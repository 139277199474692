/*global $*/
/*eslint no-undef: "error"*/
export default class Short {
    classButton = 'short--button'
    classHeader = 'page---header'

    constructor () {
        let self = this

        $(`.${self.classButton}`).click(function () {
            let block = $(this).data('block')
            let heightHeader = $(`.${self.classHeader}`).height()

            if ($(`.${block}`).length) {
                $('html,body').stop().animate(
                    {
                        scrollTop: $(`.${block}`).offset().top - heightHeader - 20
                    },
                    500
                )
            }
        })
    }
}
